const _import = file => () => Promise.resolve(require(`@/views/${file}`).default)
export default [
	{
		path: '/about',
		alise: '/about',
		component: _import('about/home'),
		redirect: '/about/index',
		children: [
			{
				path: '/about/index',
				component: _import('about/about'),
				meta: {
					title: 'about',
				}
			},
		]
	},
	// { path: '*', redirect: '/404' }

]

