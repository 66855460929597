import request from '@/api/request'
const api = {}

api.get=function(url, param) {
  return request({
    url: url,
    method: 'get',
    params: param
  })
}

api.post=function(url, param) {
  return request({
    url: url,
    method: 'post',
    data: param
  })
}

api.put=function(url, param) {
  return request({
    url: url,
    method: 'put',
    data: param
  })
}

api.delete=function(url, param) {
  return request({
    url: url,
    method: 'delete',
    data: param
  })
}

export default api