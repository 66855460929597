<template>
  <div class="login">
    <div class="head">
      <div class="max-w" @click="handleShowLogin">登录</div>
    </div>
    <el-dialog :title="title" v-model="dialogFormVisible" destroy-on-close :close-on-click-modal="false"
      :lock-scroll="false" width="500px">
      <el-radio-group v-model="loginType" size="mini">
        <el-radio-button label="password">密码登录</el-radio-button>
        <el-radio-button label="code">验证码登录</el-radio-button>
      </el-radio-group>
      <el-form ref="submitForm" :model="loginForm" :rules="rules" label-width="100px">
        <el-form-item prop="email">
          <el-input prefix-icon="el-icon-s-custom" v-model="loginForm.email" style="width: 80%;"
            placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="password" v-if="loginType=='password'">
          <el-input type="password" prefix-icon="el-icon-s-tools" v-model="loginForm.password"
            style="width: 80%;" show-password placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="code" v-if="loginType=='code'">
          <el-input prefix-icon="el-icon-s-tools" v-model="loginForm.code" style="width: 42%;"
            placeholder="验证码" />
          <el-button :loading="codeLoading" :type="`primary`" :disabled="loginForm.email ? false : true"
            style="width: 33%;margin-left:5%" @click="handleSendCode">发送验证码</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSubmit">登录</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import api from '@/api'

export default {
  name: 'Home',
  components: {
    login: '',
  },
  data() {
    return {
      dialogFormVisible: false,
      title: '登录',
      loginType: 'password',
      codeLoading: false,
      loginForm: {
        type: 'email',
        email: '1072963347@qq.com',
        password: '',
        code: '',
      },
      rules: {
        email: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      },

    }
  },
  created() {
  },
  methods: {
    handleShowLogin() {
      this.dialogFormVisible = true
    },
    handleSendCode() {
      if (this.loginType === 'password') {
        return false
      }
      if (!this.loginForm.email) {
        this.$alert('请输入账户')
        return false
      }

      this.codeLoading = true
      const form = {
        object: this.loginForm.email,
        type: 'email',
        tag: 'login'
      }
      api.post('common/send_code', form).then(response => {
        this.codeLoading = false
        this.$message.success('发送成功，请注意查收验证码')
      }).catch(() => {
        this.codeLoading = false
        // this.$message.error('网络错误')
      })
    },
    handleSubmit() {
      this.$refs['submitForm'].validate((valid) => {
        if (valid) {
          this.handleLogin()
        } else {
          return false
        }
      })

    },
    handleLogin() {
      api.post('user/login', this.loginForm).then(response => {
        this.$message.success('登录成功')
        this.dialogFormVisible = false
      }).catch(() => { })
    },
  }
}
</script>
<style lang="scss" scoped>
.head {
  padding: 0.11rem 0;
  background-color: darken($vice-bg, 20%);
  text-align: right;
  font-size: 0.32rem;
  cursor: pointer;
}
.el-form {
  text-align: left;
  margin-top: 0.53rem;
  .el-form-item {
    border: none;
    input {
      border: none;
    }
  }
}
</style>

