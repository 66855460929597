<template>
    <div class="login">
        <div class="head">
            <div class="max-w" @click="handleShowLogin">登录</div>
        </div>
        <el-dialog :title="title" v-model="dialogFormVisible" destroy-on-close
            :close-on-click-modal="false" :lock-scroll="false" width="500px">
            <el-radio-group v-model="loginType" size="mini">
                <el-radio-button label="password">密码登录</el-radio-button>
                <el-radio-button label="code">验证码登录</el-radio-button>
            </el-radio-group>
            <el-form ref="submitForm" :model="loginForm" :rules="rules" label-width="100px">
                <el-form-item prop="email">
                    <el-input prefix-icon="el-icon-s-custom" v-model="loginForm.email" style="width: 80%;"
                        placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item prop="password" v-if="loginType=='password'">
                    <el-input type="password" prefix-icon="el-icon-s-tools" v-model="loginForm.password"
                        style="width: 80%;" show-password placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item prop="code" v-if="loginType=='code'">
                    <el-input prefix-icon="el-icon-s-tools" v-model="loginForm.code" style="width: 42%;"
                        placeholder="验证码" />
                    <el-button :loading="codeLoading" :type="`primary`" :disabled="loginForm.email ? false : true"
                        style="width: 33%;margin-left:5%" @click="handleSendCode">发送验证码</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleSubmit">登录</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { reactive, onMounted, computed, ref } from 'vue'

export default {
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        console.log(ref('submitForm'))
        const loginForm = reactive({
            type: 'email',
            email: '',
            password: '',
            code: '',
        })

        onMounted(() => {
            console.log('title: ' + props.title)
        })

        const login = () => {
            emit('login', {
                phone: loginForm.phone,
                password: loginForm.password
            })
        }
        const handleShowLogin = () => {
            this.dialogFormVisible = true
        }

        const handleSubmit = () => {
            this.$refs['submitForm'].validate((valid) => {
                if (valid) {
                    this.handleLogin()
                } else {
                    return false
                }
            })

        }
        const handleLogin = () => {
            api.post('user/login', this.loginForm).then(response => {
                this.$message('登录成功')
                this.dialogFormVisible = false
            }).catch(() => { })
        }

        return {
            login,
            loginForm,
            handleShowLogin,
            handleLogin
        }
    }
}
</script>

<style lang="scss" scoped>
.head {
  padding: 0.11rem 0;
  background-color: darken($vice-bg, 20%);
  text-align: right;
  font-size: 0.32rem;
  cursor: pointer;
}
.el-form {
  text-align: left;
  margin-top: 0.53rem;
  .el-form-item {
    border: none;
    input {
      border: none;
    }
  }
}
</style>

