import Cookies from 'js-cookie'

export function getCookie(name) {
  return Cookies.get(name)
}

export function setCookie(name, token, expires) {
  const exp = new Date(expires)
  return Cookies.set(name, token, { expires: exp })
}

export function delCookie(name) {
  return Cookies.remove(name)
}
