import { createRouter, createWebHashHistory } from 'vue-router'
import newborn from '@/router/newborn'

const _import = file => () => Promise.resolve(require(`@/views/${file}`).default)

const routes = [
  {
    path: '/404',
    component: _import('404'),
    meta: {
      title: '404'
    }
  },
  {
    path: '/',
    component: _import('home'),
    meta: {
      title: 'home',
    }
  },
  {
    path: '/about/index',
    component: _import('about/index'),
    meta: {
      title: 'about',
      keepAlive: true,
    }
  },
  {
    path: '/login',
    component: _import('login/login'),
    meta: {
      title: 'login',
      keepAlive: true,
    }
  },
  // { path: '*', redirect: '/404' }

]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [...routes, ...newborn]
})

export default router
