<template>
  <div class="home">
    <div class="expect">
      <img src="@/static/images/logo.png">
      <br />
      <h1>足迹</h1> 敬请期待!
    </div>
    <div class="footer"><a target="_blank" href="https://beian.miit.gov.cn/">闽ICP备2020023105号</a></div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'Home',
  created() {
    api.get('client/school_info').then((res) => {
      console.log(3333, res)
    }).catch(() => { })
  },
}
</script>
<style lang="scss" scoped>
body {
  background-color: #fff;
}

img {
  width: 128px;
  height: 128px;
}
h1 {
  font-size: 16px;
  display: inline-block;
}

.expect {
  color: #999;
  text-align: center;
  margin-top: 30vh;
  line-height: 2;
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  height: 30px;
  line-height: 30px;
  background-color: #001;
}

.footer a {
  color: #ccc;
  text-decoration: none;
}
</style>

