<template>
  <div class="home">

    <login ref="login" />
    <div class="nav">
      <div class="max-w flex flex-ac flex-jsb">

        <div class="login">
          <img src="@/static/images/logo.png" />
        </div>
        <div class="nav-list flex">
          <div class="nav-list-item">首页</div>
          <div class="nav-list-item">云生活</div>
          <div class="nav-list-item">树洞</div>
          <div class="nav-list-item">热点</div>
          <div class="nav-list-item">社区</div>
          <div class="nav-list-item">关于我们</div>
        </div>
      </div>
    </div>
    <div class="carousel">
      <el-carousel height="12rem">
        <el-carousel-item v-for="item in 4" :key="item">
          <h3 class="small">第{{ item }}步</h3>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card class="box-card" shadow="hover">
            <template #header>
              <div class="card-header flex flex-jsb">
                <span>树洞</span>
                <el-button class="button" type="text">留下</el-button>
              </div>
            </template>
            <ul class="flex flex-w left">
              <li>111</li>
              <li>111</li>
              <li>111</li>
              <li>111</li>
              <li>111</li>
              <li>111</li>
              <li>111</li>
              <li>111</li>
              <li>111</li>
            </ul>
          </el-card>
        </el-col>
        <el-col :span="13">
          <el-card class="box-card" shadow="hover">
            <template #header>
              <div class="card-header">
                <span>云生活</span>
                <el-button class="button" type="text"></el-button>
              </div>
            </template>
            <ul>
              6666
            </ul>
          </el-card>
        </el-col>
        <el-col :span="5">
          <el-card class="box-card" shadow="hover">
            <template #header>
              <div class="card-header flex flex-jsb">
                <span>热点</span>
                <el-button class="button" type="text">留下</el-button>
              </div>
            </template>
            <ul>
              <li v-for="o in 4" :key="o" class="text item">
                {{ '列表内容 ' + o }}
              </li>
            </ul>

          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="footer"><a target="_blank" href="https://beian.miit.gov.cn/">闽ICP备2024028513号</a></div>
  </div>
</template>

<script>
import api from '@/api'
import login from '@/components/login'

export default {
  name: 'Home',
  components: {
    login,
  },
  created() {
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.home {
  // background-color: $main-bg;
}

.nav {
  padding: 0.4rem 0;
  background-color: #fff;

  .login {
    img {
      width: 2rem;
      height: 2rem;
      box-shadow: 0 0 1px $vice-bg;
    }
  }

  .nav-list-item {
    padding: 0.4rem 0.8rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      box-shadow: inset 0 0 0.8rem $vice-bg;
    }
  }
}

.carousel {
  background-color: $main-bg;

  .small {
    line-height: 12rem;
    color: #fff;
  }
}

.content {
  padding: 0.53rem;

  .box-card ul {
    height: 3.47rem;

    &.left li {
      width: calc(100% / 3 - 0.4rem);
      height: 0.8rem;
      line-height: 0.8rem;
      margin: 0.2rem;
      border-radius: 50%;
      box-shadow: inset 0 0 0.8rem $vice-bg;
    }
  }
}

.footer {
  height: 30px;
  line-height: 30px;
  background-color: #001;
}

.footer a {
  color: #ccc;
  text-decoration: none;
}</style>

